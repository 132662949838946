import {FRAMES_LENGTH_FOR_PREDICT, FRAMES_LENGTH_FOR_PREDICT_ALPHABET} from "../../../constants";

/**
 * Toggle alphabet detection mode and update related state.
 * @param {boolean} alphabetDetection - Current state of alphabet detection mode.
 * @param {function} setAlerts - Function to set the alerts.
 * @param {function} setCurrentWord - Function to set the current word.
 * @param {object} currentFramesLengthForPredict - Reference object for the current frames length.
 * @returns {boolean} - The new state of alphabet detection mode after toggling.
 */
export const toggleAlphabetDetection = (alphabetDetection, setAlerts, setCurrentWord, currentFramesLengthForPredict) => {
    if (alphabetDetection) {
        setAlerts((prevAlerts) => {
            return prevAlerts.map((alert) => {
                if (alert.alphabet) {
                    return { ...alert, alphabet: false };
                }
                return alert;
            });
        });
        setCurrentWord('');
        currentFramesLengthForPredict.current = FRAMES_LENGTH_FOR_PREDICT;
    } else {
        setCurrentWord('');
        currentFramesLengthForPredict.current = FRAMES_LENGTH_FOR_PREDICT_ALPHABET;
    }
    return !alphabetDetection;
};
