import React from "react";
import { Dialog, DialogContent, DialogContentText, Button, Checkbox, FormControlLabel, TextField } from "@mui/material";

type Props = {
    open: boolean,
    onClose: () => void,
    isOver18: boolean,
    setIsOver18: (value: boolean) => void,
    fullName: string,
    setFullName: (value: string) => void,
    userEmail: string,
    setEmail: (value: string) => void,
    handleCloseTermsModal: () => void
};

/**
 * Represents a dialog for displaying terms and conditions.
 * @param {Object} props - Component props.
 * @param {boolean} props.open - Determines whether the dialog is open.
 * @param {function} props.onClose - Callback function to close the dialog.
 * @param {boolean} props.isOver18 - The state indicating if the user is over 18.
 * @param {function} props.setIsOver18 - Callback function to set the state of being over 18.
 * @param {string} props.fullName - The user's full name.
 * @param {function} props.setFullName - Callback function to set the user's full name.
 * @param {string} props.userEmail - The user's email address.
 * @param {function} props.setEmail - Callback function to set the user's email address.
 * @param {function} props.handleCloseTermsModal - Callback function to close the terms and conditions modal.
 * @returns {JSX.Element} - JSX element representing the terms and conditions dialog.
 */
const TermsAndConditionsDialog: React.FC<Props> = ({ open, onClose, isOver18, setIsOver18, fullName, setFullName, userEmail, setEmail, handleCloseTermsModal }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="terms-and-conditions-dialog"
            fullScreen
        >
            <DialogContent style={{ textAlign: 'justify', marginRight: '5em', marginLeft: '5em' }}>
                <h2>Términos y Condiciones</h2>
                <DialogContentText id="terms-and-conditions-dialog">
                    Última actualización: 16 de Septiembre de 2023
                    Gracias por usar Dillo. Al acceder y utilizar nuestra aplicación, usted se compromete a adherirse a los siguientes términos y condiciones. Por favor, lea con atención.
                </DialogContentText>

                <h3>1. Aceptación de los Términos</h3>
                <DialogContentText>
                    Al utilizar Dillo, usted manifiesta su acuerdo con estos términos y condiciones. Si no está de acuerdo con alguno de estos términos, por favor absténgase de usar nuestra aplicación.
                </DialogContentText>

                <h3>2. Derechos de autor</h3>
                <DialogContentText>
                    Todo el contenido presente en Dillo, incluyendo diseño, texto, gráficos e interfaces, es propiedad exclusiva de la empresa y está protegido por leyes internacionales de derechos de autor.
                </DialogContentText>

                <h3>3. Uso de la aplicación</h3>
                <DialogContentText>
                    Para usar Dillo, debe ser mayor de 18 años o contar con el consentimiento de un tutor legal. Al utilizar la aplicación, garantiza cumplir con esta condición y reconoce tener la capacidad legal para aceptar estos términos.
                </DialogContentText>

                <h3>4. Uso de imágenes</h3>
                <DialogContentText>
                    Al subir imágenes a Dillo, usted otorga permiso para que estas sean utilizadas con fines de entrenamiento de modelos de inteligencia artificial. Estas imágenes se procesarán de manera anónima y no serán utilizadas con propósitos comerciales sin su consentimiento explícito.
                </DialogContentText>

                <h3>5. Política de privacidad</h3>
                <DialogContentText>
                    Estamos comprometidos con la protección de su privacidad. Por favor, consulte nuestra <a href="#">Política de Privacidad</a> para conocer más sobre cómo recopilamos, usamos y protegemos su información personal.
                </DialogContentText>

                <p>Gracias por ser parte de la comunidad Dillo.</p>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isOver18}
                            onChange={(e) => setIsOver18(e.target.checked)}
                            name="over18Checkbox"
                            color="primary"
                        />
                    }
                    label="Soy mayor de 18 años."
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label="Nombre y Apellido"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label="Correo Electrónico"
                    value={userEmail}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Button onClick={handleCloseTermsModal} style={{ backgroundColor: '#6750A4', color: 'white', marginTop: '20px' }}>
                    Acepto los términos y condiciones
                </Button>
            </DialogContent>
        </Dialog>
    );
};

export default TermsAndConditionsDialog;
