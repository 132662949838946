import {storeApi} from "../api/api";

/**
 * Handle a "Thumbs Down" action during training mode.
 * @param {function} setModalOpen - Function to set the modal open state.
 * @param {object} isModalOpenRef - Reference object for modal open state.
 * @param {function} setIsPlaying - Function to set the playing state.
 * @param {object} isPlayingRef - Reference object for playing state.
 * @param {function} setShowCountdown - Function to set the countdown visibility.
 */
export const handleThumbsDownTraining = (setModalOpen, isModalOpenRef, setIsPlaying, isPlayingRef, setShowCountdown) => {
    setModalOpen(false);
    isModalOpenRef.current = false;
    setIsPlaying(false); // Stop the countdown
    setTimeout(() => {
        setIsPlaying(true)
        isPlayingRef.current = true
        setShowCountdown(true)
    }, 100);

};

/**
 * Handle a "Thumbs Up" action during training mode.
 * @param {Object} pendingResults - The pending training results object.
 * @param {function} setSnackbarOpen - Function to set the snackbar open state.
 * @param {function} setPendingResults - Function to set the pending training results.
 * @param {function} setModalOpen - Function to set the modal open state.
 * @param {object} isModalOpenRef - Reference object for modal open state.
 * @param {function} setIsPlaying - Function to set the playing state.
 * @param {object} isPlayingRef - Reference object for playing state.
 * @param {function} setShowCountdown - Function to set the countdown visibility.
 */
export const handleThumbsUpTraining = async (pendingResults, setSnackbarOpen, setPendingResults, setModalOpen, isModalOpenRef, setIsPlaying, isPlayingRef, setShowCountdown) => {
    if (pendingResults) {
        const {recordedResults, isAlphabetDetectionOn, wordStore} = pendingResults;
        setModalOpen(false);
        isModalOpenRef.current = false;
        setPendingResults(null);  // Clear the pending results
        setIsPlaying(false); // Stop the countdown
        setTimeout(() => {
            setIsPlaying(true)
            setShowCountdown(true)
            isPlayingRef.current = true
        }, 100);

        const yPred = await storeApi(recordedResults, isAlphabetDetectionOn, wordStore);
        console.log(yPred);
        setSnackbarOpen({open: true, message: "Guardado correctamente: " + wordStore, severity: "success"});

    }
};
