const getDeviceType = () => {
    const width = window.outerWidth;

    if (width < 740) {
        return "mobile";
    }

    if (width > 900) {
        return "desktop";
    }

    return "tablet";
}

export default getDeviceType
