import {useEffect, useRef} from 'react';
import {drawConnectors, drawLandmarks} from '@mediapipe/drawing_utils';
import {
    FRAMES_LENGTH_FOR_PREDICT_ALPHABET,
    FRAMES_LENGTH_FOR_TRAINING,
} from '../../../../constants';
import {FilesetResolver, HandLandmarker} from '@mediapipe/tasks-vision';

let maxVideoWidth = 960;
let maxVideoHeight = 540;

//
// if (window.innerWidth < 720) {
//     maxVideoWidth = 960;
//     maxVideoHeight = 540;
// }

let recordedResults: any[] = [];
let lastPredictedWord = '';

const HAND_CONNECTIONS = [
    [0, 1],
    [1, 2],
    [2, 3],
    [3, 4], // Thumb
    [0, 5],
    [5, 6],
    [6, 7],
    [7, 8], // Index
    [5, 9],
    [9, 10],
    [10, 11],
    [11, 12], // Middle
    [9, 13],
    [13, 14],
    [14, 15],
    [15, 16], // Ring
    [13, 17],
    [17, 18],
    [18, 19],
    [19, 20], // Pinky
    [0, 17], // Palm
];

function useTraining(
    {
        isDialogOpen,
        alphabetDetection,
        selectedLetterRef,
        selectedWordRef,
        setSnackbar,
        setPendingResults,
        setModalOpen,
        isPlayingRef,
    }) {
    const videoElement = useRef<HTMLVideoElement | null>(null);
    const handLandmarker = useRef<HandLandmarker | null>(null);
    const canvasEl = useRef<HTMLCanvasElement | null>(null);
    const isProcessingRef = useRef(false);

    const getProgress = () => {
        return recordedResults.length;
    };

    const resetProgress = () => {
        recordedResults = [];
    };
    const alphabetDetectionRef = useRef(alphabetDetection);

    useEffect(() => {
        alphabetDetectionRef.current = alphabetDetection;
    }, [alphabetDetection]);

    async function processLandmarks(results: any) {
        if (isProcessingRef.current) return;
        isProcessingRef.current = true;

        const MIN_NUM_LANDMARKS = 5; // Define a minimum number of landmarks to ensure valid detection

        if (results.landmarks && results.landmarks.length > 0) {
            const firstHandLandmarks = results.landmarks[0];
            if (firstHandLandmarks.length >= MIN_NUM_LANDMARKS) {
                const isAlphabetDetectionOn = alphabetDetectionRef.current;

                try {
                    let lengthVerify = isAlphabetDetectionOn
                        ? FRAMES_LENGTH_FOR_PREDICT_ALPHABET
                        : FRAMES_LENGTH_FOR_TRAINING;
                    if (recordedResults.length < lengthVerify && !isDialogOpen.current) {
                        //results.image = videoElement.current?.srcObject
                        recordedResults.push(results);
                        console.log(results)
                    } else {
                        let wordStore = isAlphabetDetectionOn
                            ? selectedLetterRef.current
                            : selectedWordRef.current;
                        if (wordStore == null) {
                            setSnackbar({
                                open: true,
                                message: 'Por favor selecciona una palabra/letra',
                                severity: 'error',
                            });
                            recordedResults = [];
                        } else {
                            setPendingResults({recordedResults, isAlphabetDetectionOn, wordStore});
                            isDialogOpen.current = true;
                            setModalOpen(true); // Open the confirmation modal
                            recordedResults = [];

                            lastPredictedWord = selectedLetterRef.current;
                        }
                    }
                } catch (e) {
                    console.error('Prediction failed', e);
                    setSnackbar({open: true, message: 'No se ha podido guardar', severity: 'error'});
                    recordedResults = [];
                }
            }
        }
        console.log(recordedResults.length);
        isProcessingRef.current = false;
    }

    function processAndDrawHandLandmarks(landmarks: any, ctx: CanvasRenderingContext2D) {
        if (window.innerWidth >= 720) {
            //@ts-ignore
            drawConnectors(ctx, landmarks, HAND_CONNECTIONS, {
                color: '#00ffff',
                lineWidth: 1,
            });
            drawLandmarks(ctx, landmarks, {
                color: '#ffff29',
                lineWidth: 1,
            });
        }
    }

    async function predictWebcam() {
        const canvas = canvasEl.current;
        if (!canvas || !videoElement.current || !handLandmarker.current) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const videoWidth = videoElement.current.videoWidth;
        const videoHeight = videoElement.current.videoHeight;

        canvas.style.width = videoWidth + 'px';
        canvas.style.height = videoHeight + 'px';
        canvas.width = videoWidth;
        canvas.height = videoHeight;

        const startTimeMs = performance.now();
        const results = await handLandmarker.current.detectForVideo(
            videoElement.current,
            startTimeMs
        );

        ctx.save();
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Create a temporary canvas to store the current video frame
        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = videoWidth;
        tempCanvas.height = videoHeight;

        const tempCtx = tempCanvas.getContext('2d');
        if (tempCtx) {
            tempCtx.drawImage(videoElement.current, 0, 0, videoWidth, videoHeight);
            //@ts-ignore
            tempCanvas.toBlob((blob) => {
                if (blob) {
                    //@ts-ignore
                    results.image = blob;
                }
            }, 'image/jpeg', 0.6);
        }

        if (results.landmarks) {
            for (const landmarks of results.landmarks) {
                processAndDrawHandLandmarks(landmarks, ctx);
            }

            if (!isDialogOpen.current && !isProcessingRef.current && !isPlayingRef.current) {
                await processLandmarks(results);
            }
        }

        ctx.restore();

        if (videoElement.current?.srcObject) {
            window.requestAnimationFrame(predictWebcam);
        }
    }

    const createHandLandmarker = async () => {
        try {
            const vision = await FilesetResolver.forVisionTasks(
                'https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.14/wasm'
            );
            handLandmarker.current = await HandLandmarker.createFromOptions(vision, {
                baseOptions: {
                    modelAssetPath:
                        'https://storage.googleapis.com/mediapipe-models/hand_landmarker/hand_landmarker/float16/latest/hand_landmarker.task',
                    delegate: 'GPU',
                },
                runningMode: 'VIDEO',
                numHands: 2,
            });
        } catch (error) {
            console.error('Failed to create HandLandmarker:', error);
        }
    };

    const initCamera = async () => {
        if (!videoElement.current || !handLandmarker.current) return;

        const constraintSets = [
            {
                video: {
                    width: { ideal: 640 },
                    height: { ideal: 360 },
                    frameRate: { ideal: 30 },
                },
            },
            {
                video: {
                    width: { ideal: 640 },
                    height: { ideal: 480 },
                    frameRate: { ideal: 30 },
                },
            },
            {
                video: {
                    width: { ideal: 1280 },
                    height: { ideal: 720 },
                    frameRate: { ideal: 30 },
                },
            },
            {
                video: {
                    width: { ideal: 800 },
                    height: { ideal: 600 },
                    frameRate: { ideal: 30 },
                },
            },
            {
                video: {
                    width: { ideal: 854 },
                    height: { ideal: 480 },
                    frameRate: { ideal: 30 },
                },
            },
            {
                video: true
            }
        ];

        for (const constraints of constraintSets) {
            try {
                videoElement.current.srcObject = await navigator.mediaDevices.getUserMedia(constraints);
                videoElement.current.addEventListener("loadeddata", predictWebcam);
                console.log('Camera initialized with constraints:', constraints);

                // @ts-ignore
                maxVideoWidth = constraints.video.width.ideal
                // @ts-ignore
                maxVideoHeight  = constraints.video.height.ideal
                return; // If successful, exit the function
            } catch (error) {
                console.warn('Failed to initialize camera with constraints:', constraints, error);
                // Continue to the next set of constraints
            }
        }

        //const stream = await navigator.mediaDevices.getUserMedia(constraints);
        //videoElement.current.srcObject = stream;
        //videoElement.current.addEventListener('loadeddata', predictWebcam);
    };

    useEffect(() => {
        const initialize = async () => {
            await createHandLandmarker();
            await initCamera();
        };

        initialize();

        return () => {
            //@ts-ignore
            const tracks = videoElement.current?.srcObject?.getTracks();
            tracks?.forEach((track) => track.stop());
        };
    }, []);

    return {maxVideoHeight, maxVideoWidth, canvasEl, videoElement, getProgress, resetProgress};
}

export default useTraining;
