import React from 'react';
import './styles.css';

const Divider = ({ label }) => {
  return (
    <div className="divider">
      <span>{label}</span>
    </div>
  );
};

export default Divider;