import { useEffect } from 'react';

/**
 * Custom hook to reset the current word when alphabet detection is turned off.
 * @param {boolean} alphabetDetection - Boolean indicating whether alphabet detection is active.
 * @param {function} setCurrentWord - Function to set the current word.
 */
export const useResetWordOnAlphabetDetection = (alphabetDetection, setCurrentWord) => {
    useEffect(() => {
        if (!alphabetDetection) {
            setCurrentWord('');
        }
    }, [alphabetDetection, setCurrentWord]);
};
