import React from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
} from "@mui/material";

import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';

/**
 * Represents a confirmation dialog for saving training data.
 * @param {Object} props - Component props.
 * @param {boolean} props.isOpen - Determines whether the dialog is open.
 * @param {function} props.onClose - Callback function to close the dialog.
 * @param {string | null} props.word - The word to save.
 * @param {function} props.onThumbsUp - Callback function for confirming the save.
 * @param {function} props.onThumbsDown - Callback function for canceling the save.
 * @returns {JSX.Element} - JSX element representing the training confirmation dialog.
 */
function ConfirmationTrainingDialog({isOpen, onClose, word, onThumbsUp, onThumbsDown}: {isOpen: boolean, onClose: () => void, word: string | null, onThumbsUp: () => void, onThumbsDown: () => void}) {
    return (
        <Dialog
            open={isOpen}
            keepMounted
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
                style: {
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    borderRadius: '1em'
                },
            }}
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                   Desea guardar "<strong>{word}</strong>" ?
                </DialogContentText>
            </DialogContent>
            <div style={{padding: '0 24px 24px 24px', display: 'flex', justifyContent: 'center'}}>
                <Button onClick={onThumbsUp} variant="contained" style={{backgroundColor: '#6750A4', borderRadius: '50%', width: '56px', height: '56px', margin: '0.5em'}}>
                    <ThumbUpAltIcon style={{color: '#FFFFFF'}} />
                </Button>
                <Button onClick={onThumbsDown} variant="contained" style={{backgroundColor: '#6750A4', borderRadius: '50%', width: '56px', height: '56px' , margin: '0.5em'}}>
                    <ThumbDownAltIcon style={{color: '#FFFFFF'}} />
                </Button>
            </div>
        </Dialog>
    );
}

export default ConfirmationTrainingDialog;