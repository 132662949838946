import React from 'react';
import './styles.css';

const Button = ({ buttonText, handleOnClick }) => {
  return (
    <button 
        className="btn-container"
        onClick={() => handleOnClick()}
    >
        {buttonText}
        </button>
  );
};

export default Button;