import React, {useState} from 'react';
import PocketBase from 'pocketbase';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Divider from '../../components/Divider';
import GoogleButton from "../../components/GoogleButton";
import './styles.css'
import getDeviceType from "../../components/utils/getDeviceType.ts";
import {useNavigate} from "react-router-dom";

type Login = {
    email: string,
    password: string
}

const Login = ({setIsAuth}) => {

    const deviceType = getDeviceType()
    const navigate = useNavigate()
    const [login, setLogin] = useState<Login>({
        email: '',
        password: ''
    })
    const [isError, setIsError] = useState<boolean>(false)


    const pb = new PocketBase('https://auth.dillo.ar/');

    const handleOnLoginClick = async () => {
        const authData = await pb.collection('users').authWithPassword(
            login.email.toLowerCase(),
            login.password,
        )
            .then(data => {
                setIsError(false)
                localStorage.setItem('token_auth', data.token)
                setIsAuth(pb.authStore.isValid)
                navigate('/')
            })
            .catch(error => {
                console.log(error)
                setIsError(true)
            });
    }

    const handleOnOAuthClick = async (provider) => {
        const w = window.open()
        const authData = await pb.collection('users').authWithOAuth2({provider: provider, urlCallback: (url) => {
                w.location.href = url
            }})
            .then(data => {
                setIsError(false)
                setIsAuth(pb.authStore.isValid)
                navigate('/')
            })
            .catch(error => {
                console.log(error)
                setIsError(true)
            });
    }

    const handleOnChange = (e) => {
        setLogin({
            ...login,
            [e.target.name]: e.target.value
        })
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleOnLoginClick()
        }
    }

    return (
        <div className='login-container'>
            <div className='logo-container'>
                <img
                    src="/dillo-brand.png"
                    width='192px'
                />
            </div>
            <h1 className="title">Bienvenido</h1>
            <div className='form-container'>
                <div className={`${deviceType !== 'mobile' ? 'info-container-desktop' : 'info-container'}`}>
                    <Input
                        type='text'
                        name='email'
                        handleOnChange={(e) => handleOnChange(e)}
                        value={login.email}
                        label='Correo electrónico'
                        isError={isError as any}
                        handleKeyDown={handleKeyDown}
                        isRequired={true}
                    />
                    <Input
                        type='password'
                        name='password'
                        handleOnChange={(e) => handleOnChange(e)}
                        value={login.password}
                        label='Contraseña'
                        isError={isError as any}
                        handleKeyDown={handleKeyDown}
                        isRequired={true}
                    />
                    <Button
                        handleOnClick={() => handleOnLoginClick()}
                        buttonText='Entrar a Dillo'
                    />
                    {isError && <label className="error-message">Usuario y/o contraseña invalido</label>}
                </div>
                <Divider label='o'/>
                <div className='oAuth-container'>
                    <GoogleButton
                        buttonImage='/google-logo-search-new-svgrepo-com 1.png'
                        buttonText='Continuar con Google'
                        handleOnClick={() => handleOnOAuthClick('google')}
                    />
                </div>
                <label>¿No tienes cuenta? <a href='/signin'
                                             style={{color: '#7FCFF5', textDecoration: 'none'}}>Registrate</a></label>
            </div>
        </div>
    );
};

export default Login;