import React from "react";
import Switch from '@mui/material/Switch';

/**
 * Represents a toggle switch component.
 * @param {Object} props - Component props.
 * @param {string} props.label - The label to display next to the toggle switch.
 * @param {boolean} props.checked - The current state of the toggle switch (true for on, false for off).
 * @param {function} props.onChange - Callback function to handle changes in the toggle switch state.
 * @returns {JSX.Element} - JSX element representing the toggle switch.
 */
function ToggleSwitch({label, checked, onChange}: {label: string, checked: boolean, onChange: () => void}) {
    return (
        <div>
            <span style={{ color: 'black' }}>{label}</span>
            <Switch checked={checked} onChange={onChange} />
        </div>
    );
}

export default ToggleSwitch;